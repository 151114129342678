<template>
  <div class="sb_view_login">
    <sb-logo-full class="sb_view_login_logo" />
    <div class="sb_view_login_content">
      <div class="sb_view_login_content_top">
        <h1>Welkom bij Leeshelden</h1>
        <sb-spacer height="20" />
        <i-form
          ref="formNewPassword"
          :model="formNewPassword"
          :rules="validateNewForgotPassword"
          label-position="top"
          @submit="handleSubmit('formNewPassword')"
        >
          <!-- <FormItem
            label="Kies je gebruikersnaam"
            prop="username"
            style="padding: 0;"
          >
            <Input
              v-model="formNewPassword.username"
              placeholder="Voer een gebruikersnaam in"
            />
          </FormItem> -->
          <form-item label="Kies je wachtwoord" prop="password">
            <i-input v-model="formNewPassword.password" type="password" />
          </form-item>
          <form-item label="Herhaal dit wachtwoord" prop="passwordConfirmation">
            <i-input
              v-model="formNewPassword.passwordConfirmation"
              type="password"
            />
          </form-item>
        </i-form>
      </div>
      <div class="sb_view_login_content_bottom">
        <i-button
          type="primary"
          size="large"
          @click="handleSubmit('formNewPassword')"
        >
          Opslaan
        </i-button>
      </div>
    </div>
  </div>
</template>

<script>
import SbLogoFull from '@/components/SbLogoFull';

import ConfirmPassword from '@/graphql/mutations/ConfirmPassword.gql';

export default {
  name: 'Login',
  components: {
    SbLogoFull,
  },
  data() {
    return {
      token: null,
      username: null,
      formNewPassword: {
        password: '',
        passwordConfirmation: '',
      },
      validateNewForgotPassword: {
        password: [
          {
            required: true,
            message: 'Voer een wachtwoord in',
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 8,
            message: 'Voer een wachtwoord in van minstens 8 karakters',
          },
        ],
        passwordConfirmation: [
          {
            required: true,
            message: 'Herhaal het wachtwoord',
            trigger: 'blur',
            min: 8,
          },
          {
            type: 'string',
            min: 8,
            message: 'Voer een wachtwoord in van minstens 8 karakters',
          },
        ],
      },
    };
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.token &&
      this.$route.query.username
    ) {
      this.token = this.$route.query.token;
      this.username = this.$route.query.username;
    } else {
      throw new Error('no token or id specified');
    }
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          try {
            const data = await this.$apollo.mutate({
              mutation: ConfirmPassword,
              variables: {
                input: {
                  login: this.username,
                  token: this.token,
                  ...this.formNewPassword,
                },
              },
            });
            console.log('mutate data', data);
            if (!data) {
              this.$Message.error =
                'Er is iets fout gegaan bij het aanmaken van je gebruiker. Als dit probleem zich voor blijft doen neem dan contact op met Typetuin';
              return false;
            }
            this.$Modal.success({
              title: 'Succes',
              content: 'Je bent succesvol geregistreerd.',
              onOk: () => {
                this.$router.push({
                  name: 'Login',
                });
              },
            });
            console.log(data);
            return true;
          } catch (e) {
            console.log(e.message);
            this.$Message.error = `Er is iets fout gegaan bij het aanmaken van je gebruiker. Als dit probleem zich voor blijft doen neem dan contact op met Leeshelden: \n ${e.message}`;
            return false;
          }
        } else {
          return this.$Message.error(
            'Er zijn een aantal fouten, controleer je invoer.',
          );
        }
      });
      return true;
    },
  },
};
</script>

<style lang="scss">
.sb_view_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: $brand-lightest-gray;
  background-image: url('/static/img/bg/leesetende-plant.jpg');
  background-repeat: no-repeat;
  background-position: bottom -10px left 20%;

  .ivu-form-item {
    padding: 0 !important;
  }

  &_logo {
    padding: 100px 0 40px 0;

    @media screen and (min-height: 800px) {
      padding: 14vh 0 40px 0;
    }
  }

  &_content {
    width: 100%;
    max-width: 600px;
    background: $brand-white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba($brand-black, 0.1),
      0 5px 5px rgba($brand-black, 0.1);

    &_top {
      padding: 40px;
    }

    &_bottom {
      display: flex;
      justify-content: space-between;
      padding: 30px 40px;
      background: $brand-lightest-gray;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
</style>
