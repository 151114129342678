<template>
  <div class="sb_view_login">
    <sb-logo-full class="sb_view_login_logo" />
    <div class="sb_view_login_content">
      <div class="sb_view_login_content_top">
        <h1>Wachtwoord vergeten</h1>
        <sb-spacer height="20" />
        <i-form
          ref="formForgotPassword"
          :model="formForgotPassword"
          :rules="validateFormForgotPassword"
          label-position="top"
        >
          <form-item label="username" prop="username">
            <i-input
              v-model="formForgotPassword.username"
              placeholder="username"
              @keyup.enter.native="handleSubmit('formForgotPassword')"
            />
          </form-item>
        </i-form>
      </div>
      <div class="sb_view_login_content_bottom">
        <i-button
          type="primary"
          size="large"
          @click="handleSubmit('formForgotPassword')"
        >
          Reset wachtwoord
        </i-button>
        <i-button
          style="margin-left: 8px"
          size="large"
          @click="$router.navigateBack"
        >
          Annuleren
        </i-button>
      </div>
    </div>
  </div>
</template>

<script>
import SbLogoFull from '@/components/SbLogoFull';

import ForgotPassword from '@/graphql/mutations/ForgotPassword.gql';

export default {
  name: 'Login',
  components: {
    SbLogoFull,
  },
  data() {
    return {
      formForgotPassword: {
        username: '',
      },
      validateFormForgotPassword: {
        username: [
          {
            required: true,
            message: 'Voer een username in',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    if (this.$route?.params?.username) {
      this.formForgotPassword.username = this.$route.params.username;
    }
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        if (!valid) {
          return this.$Message.error(
            'Er zijn een aantal fouten, controleer je invoer.',
          );
        }
        const { data } = await this.$apollo.mutate({
          mutation: ForgotPassword,
          variables: {
            username: this.formForgotPassword.username,
          },
        });
        console.log(data);
        if (data.forgotPassword.message) {
          this.$Modal.success({
            title: 'Succes',
            content:
              'Als de username bij ons bekend is wordt er een link naar het bij ons bekende e-mailadres verzonden!',
            onOk: () => {
              this.$router.push({
                name: 'Login',
              });
            },
          });
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss">
.sb_view_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: $brand-lightest-gray;
  background-image: url('/static/img/bg/leesetende-plant.jpg');
  background-repeat: no-repeat;
  background-position: bottom -10px left 20%;

  .ivu-form-item {
    padding: 0 !important;
  }

  &_logo {
    padding: 100px 0 40px 0;

    @media screen and (min-height: 800px) {
      padding: 14vh 0 40px 0;
    }
  }

  &_content {
    width: 100%;
    max-width: 600px;
    background: $brand-white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba($brand-black, 0.1),
      0 5px 5px rgba($brand-black, 0.1);

    &_top {
      padding: 40px;
    }

    &_bottom {
      display: flex;
      justify-content: space-between;
      padding: 30px 40px;
      background: $brand-lightest-gray;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
</style>
