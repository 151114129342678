<template>
  <div class="sb_view_login">
    <sb-logo-full class="sb_view_login_logo" />
    <div class="sb_view_login_content">
      <div class="sb_view_login_content_top">
        <h1>Nieuw wachtwoord instellen</h1>
        <sb-spacer height="20" />
        <i-form
          ref="formNewPassword"
          :model="formNewPassword"
          :rules="validateNewForgotPassword"
          label-position="top"
        >
          <form-item label="Geef je nieuwe wachtwoord in" prop="password">
            <i-input v-model="formNewPassword.password" type="password" />
          </form-item>
          <form-item
            label="Herhaal dit nieuwe wachtwoord"
            prop="passwordConfirmation"
          >
            <i-input
              v-model="formNewPassword.passwordConfirmation"
              type="password"
            />
          </form-item>
        </i-form>
      </div>
      <div class="sb_view_login_content_bottom">
        <i-button
          type="primary"
          size="large"
          @click="handleSubmit('formNewPassword')"
        >
          Wachtwoord opslaan
        </i-button>
      </div>
    </div>
  </div>
</template>

<script>
import SbLogoFull from '@/components/SbLogoFull';
import ConfirmPassword from '@/graphql/mutations/ConfirmPassword.gql';

export default {
  name: 'Login',
  components: {
    SbLogoFull,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Voer een wachtwoord in'));
      } else {
        if (this.formNewPassword.passwordConfirmation !== '') {
          this.$refs.formNewPassword.validateField('password');
        }
        callback();
      }
    };
    const validatePasswordCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Herhaal het wachtwoord'));
      } else if (value !== this.formNewPassword.password) {
        callback(new Error('De twee wachtwoorden zijn niet hetzelfde'));
      } else {
        callback();
      }
    };
    return {
      token: null,
      username: null,
      formNewPassword: {
        password: '',
        passwordConfirmation: '',
      },
      validateNewForgotPassword: {
        password: [
          {
            validator: validatePassword,
            trigger: 'blur',
          },
        ],
        passwordConfirmation: [
          {
            validator: validatePasswordCheck,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.token &&
      this.$route.query.username
    ) {
      this.token = this.$route.query.token;
      this.username = this.$route.query.username;
    } else {
      throw new Error('no token or id specified');
    }
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          try {
            const data = await this.$apollo.mutate({
              // NEEDS QUERY
              mutation: ConfirmPassword,
              variables: {
                input: {
                  login: this.username,
                  password: this.formNewPassword.password,
                  passwordConfirmation:
                    this.formNewPassword.passwordConfirmation,
                  token: this.token,
                },
              },
            });
            console.log('mutate data', data);
            if (!data) {
              this.$Message.error(
                'Er is iets fout gegaan bij het veranderen van je wachtwoord. Als dit probleem zich voor blijft doen neem dan contact op met support@brightskills.nl',
              );
              return false;
            }
            this.$Modal.success({
              title: 'Succes',
              content:
                'Je wachtwoord is veranderd. Je kunt inloggen met je nieuwe wachtwoord.',
              onOk: () => {
                this.$router.push({
                  name: 'Login',
                });
              },
            });
            console.log(data);
            return true;
          } catch (e) {
            console.log(e.message);
            this.$Message.error(
              `Er is iets fout gegaan bij het veranderen van je wachtwoord. Als dit probleem zich voor blijft doen neem dan contact op met support@brightskills.nl: \n ${e.message}`,
            );
            return false;
          }
        } else {
          this.$Message.error(
            'Er zijn een aantal fouten, controleer je invoer.',
          );
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss">
.sb_view_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: $brand-lightest-gray;
  background-image: url('/static/img/bg/leesetende-plant.jpg');
  background-repeat: no-repeat;
  background-position: bottom -10px left 20%;

  .ivu-form-item {
    padding: 0 !important;
  }

  &_logo {
    padding: 100px 0 40px 0;

    @media screen and (min-height: 800px) {
      padding: 14vh 0 40px 0;
    }
  }

  &_content {
    width: 100%;
    max-width: 600px;
    background: $brand-white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba($brand-black, 0.1),
      0 5px 5px rgba($brand-black, 0.1);

    &_top {
      padding: 40px;
    }

    &_bottom {
      display: flex;
      justify-content: space-between;
      padding: 30px 40px;
      background: $brand-lightest-gray;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
</style>
